<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="About" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-card class="mb-3" title="Main screen background image *">
                  <div v-if="mainBgImgExisting">
                    <a style="display: block;" target="_blank" :href="mainBgImgExisting"><img style="max-width: 400px;" :src="mainBgImgExisting"></a>
                    <div><va-button small color="danger" @click="mainBgImgExisting = deleteImage(mainBgImgId, 'aboutpage_screen_image') ? '' : mainBgImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutpage_screen_image"
                    v-model="mainBgImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.aboutpage_screen_image" style="color: red;">{{ errors.aboutpage_screen_image[0] }}</div>
                  <va-button @click="uploadImage('aboutpage_screen_image', 'aboutpage.screen.image', mainBgImg[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Main screen title *"
                  v-model="mainTitle"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.aboutpage_screen_title"
                  :error-messages="errors.aboutpage_screen_title"
                  @input="delete errors.aboutpage_screen_title"
                />
                <va-input
                  label="Main screen description *"
                  v-model="mainDescription"
                  :type="'textarea'"
                  :messages="['he recommended number of characters is 400']"
                  :error="!!errors.aboutpage_screen_description"
                  :error-messages="errors.aboutpage_screen_description"
                  @input="delete errors.aboutpage_screen_description"
                />
                <va-input
                  label="Scroll title *"
                  v-model="mainScrollTitle"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.aboutpage_scroll_title"
                  :error-messages="errors.aboutpage_scroll_title"
                  @input="delete errors.aboutpage_scroll_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="History title *"
                  v-model="historyTitle"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.aboutpage_history_title"
                  :error-messages="errors.aboutpage_history_title"
                  @input="delete errors.aboutpage_history_title"
                />

                <va-card class="mb-3" title="History first icon">
                  <div v-if="historyFirstIconExisting">
                    <a style="display: block;" target="_blank" :href="historyFirstIconExisting"><img style="max-width: 400px;" :src="historyFirstIconExisting"></a>
                    <div><va-button small color="danger" @click="historyFirstIconExisting = deleteImage(historyFirstIconId, 'aboutpage_history_icon_first') ? '' : historyFirstIconExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutpage_history_icon_first"
                    v-model="historyFirstIcon"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.aboutpage_history_icon_first" style="color: red;">{{ errors.aboutpage_history_icon_first[0] }}</div>
                  <va-button @click="uploadImage('aboutpage_history_icon_first', 'aboutpage.history.icon.first', historyFirstIcon[0])">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="History second icon">
                  <div v-if="historySecondIconExisting">
                    <a style="display: block;" target="_blank" :href="historySecondIconExisting"><img style="max-width: 400px;" :src="historySecondIconExisting"></a>
                    <div><va-button small color="danger" @click="historySecondIconExisting = deleteImage(historySecondIconId, 'aboutpage_history_icon_second') ? '' : historySecondIconExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutpage_history_icon_second"
                    v-model="historySecondIcon"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.aboutpage_history_icon_second" style="color: red;">{{ errors.aboutpage_history_icon_second[0] }}</div>
                  <va-button @click="uploadImage('aboutpage_history_icon_second', 'aboutpage.history.icon.second', historySecondIcon[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="History content first block *"
                  v-model="historyFirstBlock"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 500']"
                  :error="!!errors.aboutpage_history_content_first"
                  :error-messages="errors.aboutpage_history_content_first"
                  @input="delete errors.aboutpage_history_content_first"
                />
                <va-input
                  label="History content second block *"
                  v-model="historySecondBlock"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 500']"
                  :error="!!errors.aboutpage_history_content_second"
                  :error-messages="errors.aboutpage_history_content_second"
                  @input="delete errors.aboutpage_history_content_second"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-toggle label="Show owner *" small v-model="ownerShow"/>
                <va-input
                  label="Owner title"
                  v-model="ownerTitle"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.aboutpage_owner_title"
                  :error-messages="errors.aboutpage_owner_title"
                  @input="delete errors.aboutpage_owner_title"
                />
                <va-input
                  label="Owner content"
                  v-model="ownerContent"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 300']"
                  :error="!!errors.aboutpage_owner_content"
                  :error-messages="errors.aboutpage_owner_content"
                  @input="delete errors.aboutpage_owner_content"
                />
                <va-card class="mb-3" title="Owner image">
                  <div v-if="ownerImgExisting">
                    <a style="display: block;" target="_blank" :href="ownerImgExisting"><img style="max-width: 400px;" :src="ownerImgExisting"></a>
                    <div><va-button small color="danger" @click="ownerImgExisting = deleteImage(ownerImgId, 'aboutpage_owner_image') ? '' : ownerImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutpage_owner_image"
                    v-model="ownerImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.aboutpage_owner_image" style="color: red;">{{ errors.aboutpage_owner_image[0] }}</div>
                  <va-button @click="uploadImage('aboutpage_owner_image', 'aboutpage.owner.image', ownerImg[0])">Завантажити</va-button>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-input
                  label="Team title *"
                  v-model="teamTitle"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.aboutpage_team_title"
                  :error-messages="errors.aboutpage_team_title"
                  @input="delete errors.aboutpage_team_title"
                />
                <va-input
                  label="Team content *"
                  v-model="teamContent"
                  :messages="['The recommended number of characters is 850']"
                  :type="'textarea'"
                  :error="!!errors.aboutpage_team_content"
                  :error-messages="errors.aboutpage_team_content"
                  @input="delete errors.aboutpage_team_content"
                />
                <va-input
                  label="Team item title *"
                  v-model="teamItemTitle"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.aboutpage_team_item_title"
                  :error-messages="errors.aboutpage_team_item_title"
                  @input="delete errors.aboutpage_team_item_title"
                />
                <va-input
                  label="Team item content *"
                  v-model="teamItemContent"
                  :messages="['The recommended number of characters is 250']"
                  :type="'textarea'"
                  :error="!!errors.aboutpage_team_item_content"
                  :error-messages="errors.aboutpage_team_item_content"
                  @input="delete errors.aboutpage_team_item_content"
                />
                <va-input
                  label="Team button label *"
                  v-model="teamButton"
                  :messages="['The recommended number of characters is 20']"
                  :error="!!errors.aboutpage_team_btn_label"
                  :error-messages="errors.aboutpage_team_btn_label"
                  @input="delete errors.aboutpage_team_btn_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 4">
                <va-input
                  label="Social responsibility title *"
                  v-model="socialTitle"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.aboutpage_responsibility_title"
                  :error-messages="errors.aboutpage_responsibility_title"
                  @input="delete errors.aboutpage_responsibility_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 5">
                <va-input
                  label="Partners title *"
                  v-model="partnersTitle"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.aboutpage_partner_title"
                  :error-messages="errors.aboutpage_partner_title"
                  @input="delete errors.aboutpage_partner_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 6">
                <va-input
                  label="News title *"
                  v-model="newsTitle"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.aboutpage_news_title"
                  :error-messages="errors.aboutpage_news_title"
                  @input="delete errors.aboutpage_news_title"
                />
                <va-input
                  label="News item link label *"
                  v-model="newsItemLinkLabel"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.aboutpage_news_item_link_label"
                  :error-messages="errors.aboutpage_news_item_link_label"
                  @input="delete errors.aboutpage_news_item_link_label"
                />
                <va-input
                  label="News button label *"
                  v-model="newsButton"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.aboutpage_news_btn_label"
                  :error-messages="errors.aboutpage_news_btn_label"
                  @input="delete errors.aboutpage_news_btn_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      mainTitle: '',
      mainBgImg: [],
      mainBgImgExisting: '',
      mainBgImgId: '',
      mainDescription: '',
      mainScrollTitle: '',

      historyTitle: '',
      historyFirstIcon: [],
      historyFirstIconId: '',
      historyFirstIconExisting: '',
      historySecondIcon: [],
      historySecondIconId: '',
      historySecondIconExisting: '',
      historyFirstBlock: '',
      historySecondBlock: '',

      ownerShow: false,
      ownerTitle: '',
      ownerContent: '',
      ownerImg: [],
      ownerImgId: '',
      ownerImgExisting: '',

      teamTitle: '',
      teamContent: '',
      teamItemTitle: '',
      teamItemContent: '',
      teamButton: '',

      socialTitle: '',

      partnersTitle: '',

      newsTitle: '',
      newsItemLinkLabel: '',
      newsButton: '',

      errors: [],

      tabTitles: ['Main', 'History', 'Owner', 'Team', 'Social responsibility', 'Partners', 'News'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        aboutpage_screen_title: this.mainTitle,
        aboutpage_screen_description: this.mainDescription,
        aboutpage_scroll_title: this.mainScrollTitle,

        aboutpage_history_title: this.historyTitle,
        aboutpage_history_content_first: this.historyFirstBlock,
        aboutpage_history_content_second: this.historySecondBlock,

        aboutpage_owner_show: this.ownerShow ? 1 : 0,
        aboutpage_owner_title: this.ownerTitle,
        aboutpage_owner_content: this.ownerContent,

        aboutpage_team_title: this.teamTitle,
        aboutpage_team_content: this.teamContent,
        aboutpage_team_item_title: this.teamItemTitle,
        aboutpage_team_item_content: this.teamItemContent,
        aboutpage_team_btn_label: this.teamButton,

        aboutpage_responsibility_title: this.socialTitle,

        aboutpage_partner_title: this.partnersTitle,

        aboutpage_news_title: this.newsTitle,
        aboutpage_news_item_link_label: this.newsItemLinkLabel,
        aboutpage_news_btn_label: this.newsButton,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/upload/1`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/1/delete-file/${id}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.aboutpage_screen_title || !!this.errors.aboutpage_screen_description || !!this.errors.aboutpage_scroll_title || !!this.errors.aboutpage_screen_image
      } else if (tab === 1) {
        return !!this.errors.aboutpage_history_title || !!this.errors.aboutpage_history_content_first || !!this.errors.aboutpage_history_content_second ||
          !!this.errors.aboutpage_history_icon_first || !!this.errors.aboutpage_history_icon_second
      } else if (tab === 2) {
        return !!this.errors.aboutpage_owner_show || !!this.errors.aboutpage_owner_title || !!this.errors.aboutpage_owner_content || !!this.errors.aboutpage_owner_image
      } else if (tab === 3) {
        return !!this.errors.aboutpage_team_title || !!this.errors.aboutpage_team_content ||
          !!this.errors.aboutpage_team_item_title || !!this.errors.aboutpage_team_item_content ||
          !!this.errors.aboutpage_team_btn_label
      } else if (tab === 4) {
        return !!this.errors.aboutpage_responsibility_title
      } else if (tab === 5) {
        return !!this.errors.aboutpage_partner_title
      } else if (tab === 6) {
        return !!this.errors.aboutpage_news_title || !!this.errors.aboutpage_news_item_link_label || !!this.errors.aboutpage_news_btn_label
      }
      return false
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/1`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/validate/1`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/1`)
        .then(response => {
          // console.log(response.data)
          this.mainTitle = response.data.aboutpage_screen_title
          this.mainDescription = response.data.aboutpage_screen_description
          this.mainScrollTitle = response.data.aboutpage_scroll_title

          this.historyTitle = response.data.aboutpage_history_title
          this.historyFirstBlock = response.data.aboutpage_history_content_first
          this.historySecondBlock = response.data.aboutpage_history_content_second

          this.ownerShow = response.data.aboutpage_owner_show === '1'
          this.ownerTitle = response.data.aboutpage_owner_title
          this.ownerContent = response.data.aboutpage_owner_content

          this.teamTitle = response.data.aboutpage_team_title
          this.teamContent = response.data.aboutpage_team_content
          this.teamItemTitle = response.data.aboutpage_team_item_title
          this.teamItemContent = response.data.aboutpage_team_item_content
          this.teamButton = response.data.aboutpage_team_btn_label

          this.socialTitle = response.data.aboutpage_responsibility_title

          this.partnersTitle = response.data.aboutpage_partner_title

          this.newsTitle = response.data.aboutpage_news_title
          this.newsItemLinkLabel = response.data.aboutpage_news_item_link_label
          this.newsButton = response.data.aboutpage_news_btn_label

          this.mainBgImgExisting = response.data.aboutpage_screen_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.aboutpage_screen_image : ''
          this.mainBgImgId = response.data.aboutpage_screen_image_id
          this.historyFirstIconExisting = response.data.aboutpage_history_icon_first ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.aboutpage_history_icon_first : ''
          this.historyFirstIconId = response.data.aboutpage_history_icon_first_id
          this.historySecondIconExisting = response.data.aboutpage_history_icon_second ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.aboutpage_history_icon_second : ''
          this.historySecondIconId = response.data.aboutpage_history_icon_second_id
          this.ownerImgExisting = response.data.aboutpage_owner_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.aboutpage_owner_image : ''
          this.ownerImgId = response.data.aboutpage_owner_image_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
